// const 路由文件严格按照目录树结构来写,请勿随意添加,防止后期难以维护

const EmptyLayout = {
  name: 'EmptyLayout',
  render() {
    return <router-view></router-view>;
  },
};

export default [
  // 登录
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../views/Login.vue'),
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      {
        path: 'staging/staging/staging',
        name: 'stagingList',
        component: () =>
          import(/* webpackChunkName: 'stagingList' */ '../views/staging/staging/staging.vue'),
        meta: {
          pageTitle: '工作台',
        },
      },
      {
        path: 'staging/staging/pileMonitor',
        name: 'pileMonitorList',
        component: () =>
          import(
            /* webpackChunkName: 'pileMonitorList' */ '../views/staging/staging/pileMonitor.vue'
          ),
        meta: {
          pageTitle: '电桩监控',
        },
      },
    ],
  },

  // 订单管理
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  订单管理 充电订单 实时订单
      {
        path: 'orderManagement/realTimeOrder/List',
        name: 'realTimeOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'realTimeOrderList' */ '../views/orderManagement/realTimeOrder/List.vue'
          ),
        meta: {
          pageTitle: '实时订单',
        },
      },
      //  订单管理 充电订单 实时订单详情
      {
        path: 'orderManagement/realTimeOrder/Detail',
        name: 'realTimeOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'realTimeOrderDetail' */ '../views/orderManagement/realTimeOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '实时订单详情',
        },
      },
      //  订单管理 充电订单 实时订单详情
      {
        path: 'orderManagement/test/realTimeOrder/Detail',
        name: 'realTimeOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'realTimeOrderDetail' */ '../views/orderManagement/realTimeOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '测试实时订单详情',
        },
      },
      {
        path: 'orderManagement/privatePileShare/realTimeOrder/Detail',
        name: 'realTimeOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'realTimeOrderDetail' */ '../views/orderManagement/realTimeOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '私桩共享实时订单详情',
        },
      },
      //  订单管理 充电订单 历史订单
      {
        path: 'orderManagement/historicalOrder/List',
        name: 'historicalOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'historicalOrderList' */ '../views/orderManagement/historicalOrder/List.vue'
          ),
        meta: {
          pageTitle: '历史订单',
        },
      },
      //  订单管理 充电订单 历史订单详情
      {
        path: 'orderManagement/historicalOrder/Detail',
        name: 'historicalOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'historicalOrderDetail' */ '../views/orderManagement/historicalOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '历史订单详情',
        },
      },
      //  订单管理 充电订单 历史订单详情
      {
        path: 'orderManagement/test/historicalOrder/Detail',
        name: 'historicalOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'historicalOrderDetail' */ '../views/orderManagement/historicalOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '测试历史订单详情',
        },
      },
      //  订单管理 充电订单 历史订单详情
      {
        path: 'orderManagement/privatePileShare/historicalOrder/Detail',
        name: 'historicalOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'historicalOrderDetail' */ '../views/orderManagement/historicalOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '私桩共享历史订单详情',
        },
      },
      //  订单管理 充电订单 异常订单
      {
        path: 'orderManagement/abnormalOrder/List',
        name: 'abnormalOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'abnormalOrderList' */ '../views/orderManagement/abnormalOrder/List.vue'
          ),
        meta: {
          pageTitle: '异常订单',
        },
      },
      //  订单管理 充电订单 异常订单详情
      {
        path: 'orderManagement/abnormalOrder/Detail',
        name: 'abnormalOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'abnormalOrderDetail' */ '../views/orderManagement/abnormalOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '异常订单详情',
        },
      },
      //  订单管理 充电订单 异常订单详情
      {
        path: 'orderManagement/test/abnormalOrder/Detail',
        name: 'abnormalOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'abnormalOrderDetail' */ '../views/orderManagement/abnormalOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '测试异常订单详情',
        },
      },
      {
        path: 'orderManagement/privatePileShare/abnormalOrder/Detail',
        name: 'abnormalOrderDetail',
        component: () =>
          import(
            /* webpackChunkName: 'abnormalOrderDetail' */ '../views/orderManagement/abnormalOrder/Detail.vue'
          ),
        meta: {
          pageTitle: '私桩共享异常订单详情',
        },
      },
      //  订单管理 占位订单 占位订单
      {
        path: 'orderManagement/placeholderOrder/List',
        name: 'placeholderOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'placeholderOrderList' */ '../views/orderManagement/placeholderOrder/List.vue'
          ),
        meta: {
          pageTitle: '占位订单',
        },
      },

      //  订单管理 测试订单 测试实时订单
      {
        path: 'orderManagement/realTimeOrder/TestList',
        name: 'testRealTimeOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'realTimeOrderList' */ '../views/orderManagement/realTimeOrder/TestList.vue'
          ),
        meta: {
          pageTitle: '测试实时订单',
        },
      },
      //  订单管理 测试订单 私桩共享实时订单
      {
        path: 'orderManagement/realTimeOrder/shareList',
        name: 'shareRealTimeOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'shareRealTimeOrderList' */ '../views/orderManagement/realTimeOrder/shareList.vue'
          ),
        meta: {
          pageTitle: '私桩共享实时订单',
        },
      },

      //  订单管理 充电订单 测试历史订单
      {
        path: 'orderManagement/historicalOrder/TestList',
        name: 'historicalOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'historicalOrderList' */ '../views/orderManagement/historicalOrder/TestList.vue'
          ),
        meta: {
          pageTitle: '测试历史订单',
        },
      },
      //  订单管理 测试订单 私桩共享历史订单
      {
        path: 'orderManagement/historicalOrder/shareList',
        name: 'shareRealTimeOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'shareRealTimeOrderList' */ '../views/orderManagement/historicalOrder/shareList.vue'
          ),
        meta: {
          pageTitle: '私桩共享历史订单',
        },
      },

      //  订单管理 充电订单 测试异常订单
      {
        path: 'orderManagement/abnormalOrder/TestList',
        name: 'testAbnormalOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'testAbnormalOrderList' */ '../views/orderManagement/abnormalOrder/TestList.vue'
          ),
        meta: {
          pageTitle: '测试异常订单',
        },
      },
      //  订单管理 测试订单 私桩共享异常订单
      {
        path: 'orderManagement/abnormalOrder/shareList',
        name: 'shareAbnormalOrderList',
        component: () =>
          import(
            /* webpackChunkName: 'shareRealTimeOrderList' */ '../views/orderManagement/abnormalOrder/shareList.vue'
          ),
        meta: {
          pageTitle: '私桩共享异常订单',
        },
      },
    ],
  },
  // 财务管理
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  财务管理 账号管理 C端司机账号
      {
        path: 'financialManagement/accountManagement/cSideDriverAccounts',
        name: 'CSideDriverAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'CSideDriverAccounts' */ '../views/financialManagement/accountManagement/cSideDriverAccount/CSideDriverAccounts.vue'
          ),
        meta: {
          pageTitle: 'C端司机账户',
        },
      },
      //  财务管理 账号管理 C端司机账号
      {
        path: 'financialManagement/accountManagement/cSideDriverAccountsDetail',
        name: 'CSideDriverAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'CSideDriverAccountsDetail' */ '../views/financialManagement/accountManagement/cSideDriverAccount/CSideDriverAccountsDetail.vue'
          ),
        meta: {
          pageTitle: 'C端司机账户',
        },
      },
      //  财务管理 账号管理 C端司机账号
      {
        path: 'financialManagement/accountManagement/refund',
        name: 'refundList',
        component: () =>
          import(
            /* webpackChunkName: 'refundList' */ '../views/financialManagement/accountManagement/cSideDriverAccount/refundList.vue'
          ),
        meta: {
          pageTitle: '退款管理',
        },
      },
      //  财务管理 账号管理 第三方支付账户
      {
        path: 'financialManagement/accountManagement/thirdPartyPaymentAccounts',
        name: 'ThirdPartyPaymentAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'CSideDriverAccounts' */ '../views/financialManagement/accountManagement/cSideDriverAccount/ThirdPartyPaymentAccounts.vue'
          ),
        meta: {
          pageTitle: '第三方支付账户',
        },
      },
      //  财务管理 账号管理 客户司机账户
      {
        path: 'financialManagement/customerAccount/customDriverAccounts',
        name: 'CustomDriverAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'CustomDriverAccounts' */ '../views/financialManagement/accountManagement/customerAccount/CustomDriverAccounts.vue'
          ),
        meta: {
          pageTitle: '司机账户',
        },
      },
      //  财务管理 账号管理 客户司机账户详情
      {
        path: 'financialManagement/customerAccount/customDriverAccountsDetail',
        name: 'CustomDriverAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'CustomDriverAccountsDetail' */ '../views/financialManagement/accountManagement/customerAccount/CustomDriverAccountsDetail.vue'
          ),
        meta: {
          pageTitle: '司机账户详情',
        },
      },
      //  财务管理 账号管理 客户账户
      {
        path: 'financialManagement/customerAccount/customerAccounts',
        name: 'CustomerAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'CustomerAccounts' */ '../views/financialManagement/accountManagement/customerAccount/CustomerAccounts.vue'
          ),
        meta: {
          pageTitle: '客户账户',
        },
      },
      //  财务管理 账号管理 客户账户详情
      {
        path: 'financialManagement/customerAccount/customerAccountsDetail',
        name: 'CustomerAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'CustomerAccountsDetail' */ '../views/financialManagement/accountManagement/customerAccount/CustomerAccountsDetail.vue'
          ),
        meta: {
          pageTitle: '客户账户',
        },
      },
      //  财务管理 账号管理 子公司账户 内部机构账户
      {
        path: 'financialManagement/subsidiaryAccount/internalCustomerAccounts',
        name: 'InternalCustomerAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'InternalCustomerAccounts' */ '../views/financialManagement/accountManagement/subsidiaryAccount/InternalCustomerAccounts.vue'
          ),
        meta: {
          pageTitle: '内部机构账户',
        },
      },
      //  财务管理 账号管理 子公司账户 内部机构账户详情
      {
        path: 'financialManagement/subsidiaryAccount/internalCustomerAccountsDetail',
        name: 'InternalCustomerAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'InternalCustomerAccountsDetail' */ '../views/financialManagement/accountManagement/subsidiaryAccount/InternalCustomerAccountsDetail.vue'
          ),
        meta: {
          pageTitle: '内部机构账户详情',
        },
      },
      //  财务管理 账号管理 子公司账户 机构员工账户
      {
        path: 'financialManagement/subsidiaryAccount/subDriverAccounts',
        name: 'SubDriverAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'SubDriverAccounts' */ '../views/financialManagement/accountManagement/subsidiaryAccount/SubDriverAccounts.vue'
          ),
        meta: {
          pageTitle: '机构员工账户',
        },
      },
      //  财务管理 账号管理 子公司账户 机构员工账户详情
      {
        path: 'financialManagement/subsidiaryAccount/subDriverAccountsDetail',
        name: 'SubDriverAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'SubDriverAccountsDetail' */ '../views/financialManagement/accountManagement/subsidiaryAccount/SubDriverAccountsDetail.vue'
          ),
        meta: {
          pageTitle: '机构员工账户详情',
        },
      },
      //  财务管理 账号管理 互联互通账户 互联互通账户
      {
        path: 'financialManagement/interconnectedAccount/interconnectedAccounts',
        name: 'InterconnectedAccounts',
        component: () =>
          import(
            /* webpackChunkName: 'InterconnectedAccounts' */ '../views/financialManagement/accountManagement/interconnectedAccount/InterconnectedAccounts.vue'
          ),
        meta: {
          pageTitle: '互联互通账户',
        },
      },
      //  财务管理 账号管理 互联互通账户 互联互通账户详情
      {
        path: 'financialManagement/interconnectedAccount/interconnectedAccountsDetail',
        name: 'InterconnectedAccountsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'InterconnectedAccountsDetail' */ '../views/financialManagement/accountManagement/interconnectedAccount/InterconnectedAccountsDetail.vue'
          ),
        meta: {
          pageTitle: '互联互通账户详情',
        },
      },

      //  财务管理 对账管理 平台收支账单
      {
        path: 'financialManagement/platformReceiptsPaymentsStatement/platformReceiptsPaymentsStatements',
        name: 'PlatformReceiptsPaymentsStatements',
        component: () =>
          import(
            /* webpackChunkName: 'PlatformReceiptsPaymentsStatements' */ '../views/financialManagement/accountReconciliationManagement/platformReceiptsPaymentsStatement/PlatformReceiptsPaymentsStatements.vue'
          ),
        meta: {
          pageTitle: '平台收支账单',
        },
      },
      //  财务管理 对账管理 平台收支账单明细
      {
        path: 'financialManagement/platformReceiptsPaymentsStatement/platformReceiptsPaymentsStatementsDetail',
        name: 'PlatformReceiptsPaymentsStatementsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'PlatformReceiptsPaymentsStatementsDetail' */ '../views/financialManagement/accountReconciliationManagement/platformReceiptsPaymentsStatement/PlatformReceiptsPaymentsStatementsDetail.vue'
          ),
        meta: {
          pageTitle: '平台收支账单明细',
        },
      },
      //  财务管理 对账管理 互联互通账单
      {
        path: 'financialManagement/interconnectedBillingStatement/interconnectedBillingStatements',
        name: 'InterconnectedBillingStatements',
        component: () =>
          import(
            /* webpackChunkName: 'InterconnectedBillingStatements' */ '../views/financialManagement/accountReconciliationManagement/interconnectedBillingStatement/InterconnectedBillingStatements.vue'
          ),
        meta: {
          pageTitle: '互联互通账单',
        },
      },
      //  财务管理 对账管理 互联互通账单明细
      {
        path: 'financialManagement/interconnectedBillingStatement/interconnectedBillingStatementsDetail',
        name: 'InterconnectedBillingStatementsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'InterconnectedBillingStatementsDetail' */ '../views/financialManagement/accountReconciliationManagement/interconnectedBillingStatement/InterconnectedBillingStatementsDetail.vue'
          ),
        meta: {
          pageTitle: '互联互通账单明细',
        },
      },
      //  财务管理 对账管理 商户账单
      {
        path: 'financialManagement/merchantStatement/merchantStatements',
        name: 'MerchantStatements',
        component: () =>
          import(
            /* webpackChunkName: 'MerchantStatements' */ '../views/financialManagement/accountReconciliationManagement/merchantStatement/MerchantStatements.vue'
          ),
        meta: {
          pageTitle: '商户账单',
        },
      },
      //  财务管理 对账管理 商户账单明细
      {
        path: 'financialManagement/merchantStatement/merchantStatementsDetail',
        name: 'MerchantStatementsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'MerchantStatementsDetail' */ '../views/financialManagement/accountReconciliationManagement/merchantStatement/MerchantStatementsDetail.vue'
          ),
        meta: {
          pageTitle: '商户账单明细',
        },
      },
      //  财务管理 对账管理 平台资金流账单
      {
        path: 'financialManagement/platformFundFlowStatement/platformFundFlowStatements',
        name: 'PlatformFundFlowStatements',
        component: () =>
          import(
            /* webpackChunkName: 'PlatformFundFlowStatements' */ '../views/financialManagement/accountReconciliationManagement/platformFundFlowStatement/PlatformFundFlowStatements.vue'
          ),
        meta: {
          pageTitle: '平台资金流账单',
        },
      },
      //  财务管理 对账管理 占位费收支账单
      {
        path: 'financialManagement/occupancyFeeReceiptsPayments/occupancyFeeReceiptsPayments',
        name: 'OccupancyFeeReceiptsPayments',
        component: () =>
          import(
            /* webpackChunkName: 'OccupancyFeeReceiptsPayments' */ '../views/financialManagement/accountReconciliationManagement/occupancyFeeReceiptsPayments/OccupancyFeeReceiptsPayments.vue'
          ),
        meta: {
          pageTitle: '占位费收支账单',
        },
      },
      //  财务管理 对账管理 占位费收支账单详情
      {
        path: 'financialManagement/occupancyFeeReceiptsPayments/occupancyFeeReceiptsPaymentsDetail',
        name: 'OccupancyFeeReceiptsPaymentsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'OccupancyFeeReceiptsPaymentsDetail' */ '../views/financialManagement/accountReconciliationManagement/occupancyFeeReceiptsPayments/OccupancyFeeReceiptsPaymentsDetail.vue'
          ),
        meta: {
          pageTitle: '占位费收支账单明细',
        },
      },
      //  财务管理 对账管理 客户账单
      {
        path: 'financialManagement/customerStatement/customerStatements',
        name: 'CustomerStatements',
        component: () =>
          import(
            /* webpackChunkName: 'CustomerStatements' */ '../views/financialManagement/accountReconciliationManagement/customerStatement/CustomerStatements.vue'
          ),
        meta: {
          pageTitle: '客户账单',
        },
      },
      //  财务管理 对账管理 客户账单 客户账单明细
      {
        path: 'financialManagement/customerStatement/customerStatementsDetail',
        name: 'CustomerStatementsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'CustomerStatementsDetail' */ '../views/financialManagement/accountReconciliationManagement/customerStatement/CustomerStatementsDetail.vue'
          ),
        meta: {
          pageTitle: '客户账单明细',
        },
      },
      //  财务管理 对账管理 分成信息 分成信息明细
      {
        path: '/financialManagement/dividedIntoInformation/dividedIntoInformations',
        name: 'DividedIntoInformations',
        component: () =>
          import(
            /* webpackChunkName: 'DividedIntoInformations' */ '../views/financialManagement/accountReconciliationManagement/dividedIntoInformation/dividedIntoInformations.vue'
          ),
        meta: {
          pageTitle: '分成信息',
        },
      },
      //  财务管理 对账管理 红包记录
      {
        path: '/financialManagement/redPack/list',
        name: 'redPacks',
        component: () =>
          import(
            /* webpackChunkName: 'DividedIntoInformations' */ '../views/financialManagement/accountReconciliationManagement/redPack/List.vue'
          ),
        meta: {
          pageTitle: '红包记录',
        },
      },
      //  财务管理 对账管理 红包记录 使用记录
      {
        path: '/financialManagement/redPack/useRecord',
        name: 'useRecord',
        component: () =>
          import(
            /* webpackChunkName: 'DividedIntoInformations' */ '../views/financialManagement/accountReconciliationManagement/redPack/useRecord.vue'
          ),
        meta: {
          pageTitle: '红包使用记录',
        },
      },
      //  财务管理 对账管理 红包记录 发放记录
      {
        path: '/financialManagement/redPack/grantRecord',
        name: 'grantRecord',
        component: () =>
          import(
            /* webpackChunkName: 'DividedIntoInformations' */ '../views/financialManagement/accountReconciliationManagement/redPack/grantRecord.vue'
          ),
        meta: {
          pageTitle: '红包发放记录',
        },
      },
      //  财务管理 对账管理 客户账单 子机构账单
      {
        path: 'financialManagement/subAgencyBill/subAgencyBill',
        name: 'SubAgencyBill',
        component: () =>
          import(
            /* webpackChunkName: 'SubAgencyBill' */ '../views/financialManagement/accountReconciliationManagement/customerStatement/subAgencyBill/SubAgencyBill.vue'
          ),
        meta: {
          pageTitle: '子机构账单',
        },
      },
      //  财务管理 对账管理 客户账单 子机构账单明细
      {
        path: 'financialManagement/subAgencyBill/subAgencyBillDetail',
        name: 'SubAgencyBillDetail',
        component: () =>
          import(
            /* webpackChunkName: 'SubAgencyBillDetail' */ '../views/financialManagement/accountReconciliationManagement/customerStatement/subAgencyBill/SubAgencyBillDetail.vue'
          ),
        meta: {
          pageTitle: '子机构账单明细',
        },
      },
      //  财务管理 发票管理 平台发票管理
      {
        path: 'financialManagement/invoiceManagement/InvoiceManagements',
        name: 'InvoiceManagements',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceManagements' */ '../views/financialManagement/invoiceManagement/InvoiceManagements.vue'
          ),
        meta: {
          pageTitle: '平台发票管理',
        },
      },
      //  财务管理 发票管理 开票处理
      {
        path: 'financialManagement/invoiceManagement/invoiceIssuingProcessing',
        name: 'InvoiceIssuingProcessing',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceIssuingProcessing' */ '../views/financialManagement/invoiceManagement/InvoiceIssuingProcessing.vue'
          ),
        meta: {
          pageTitle: '开票处理',
        },
      },

      //  财务管理 发票管理 已开票
      {
        path: 'financialManagement/invoiceManagement/invoiceDetailsIssued',
        name: 'InvoiceDetailsIssued',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceDetailsIssued' */ '../views/financialManagement/invoiceManagement/InvoiceDetailsIssued.vue'
          ),
        meta: {
          pageTitle: '发票管理-已开票',
        },
      },
      //  财务管理 发票管理 已退回
      {
        path: 'financialManagement/invoiceManagement/invoiceDetailsReturned',
        name: 'InvoiceDetailsReturned',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceDetailsReturned' */ '../views/financialManagement/invoiceManagement/InvoiceDetailsReturned.vue'
          ),
        meta: {
          pageTitle: '发票管理-已退回',
        },
      },
      //  财务管理 发票管理 待开票
      {
        path: 'financialManagement/invoiceManagement/invoiceDetailsToBeIssued',
        name: 'InvoiceDetailsToBeIssued',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceDetailsToBeIssued' */ '../views/financialManagement/invoiceManagement/InvoiceDetailsToBeIssued.vue'
          ),
        meta: {
          pageTitle: '发票管理-待开票',
        },
      },
      //  财务管理 发票管理 已驳回
      {
        path: 'financialManagement/invoiceManagement/invoiceDetailsRejected',
        name: 'InvoiceDetailsRejected',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceDetailsRejected' */ '../views/financialManagement/invoiceManagement/InvoiceDetailsRejected.vue'
          ),
        meta: {
          pageTitle: '发票管理-已驳回',
        },
      },
      //  财务管理 报表 用户余额变动报表
      {
        path: '/financialManagement/reportforms/publicPile/List',
        name: 'publicPileList',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceDetailsRejected' */ '../views/financialManagement/reportforms/publicPile/List.vue'
          ),
        meta: {
          pageTitle: '用户余额变动报表',
        },
      },
      //  财务管理 报表 公共充电桩运营报表
      {
        path: '/financialManagement/reportforms/stationOperation/List',
        name: 'stationOperationList',
        component: () =>
          import(
            /* webpackChunkName: 'InvoiceDetailsRejected' */ '../views/financialManagement/reportforms/stationOperation/List.vue'
          ),
        meta: {
          pageTitle: '公共充电站运营报表',
        },
      },
    ],
  },
  // 数据统计
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      // 数据统计 平台统计 充电趋势统计
      {
        path: 'dataStatistics/platformStatistics/chargingTrendStatistics/List',
        name: 'chargingTrendStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'chargingTrendStatisticsList' */ '../views/dataStatistics/platformStatistics/chargingTrendStatistics/List.vue'
          ),
        meta: {
          pageTitle: '充电趋势统计',
        },
      },
      // 数据统计 平台统计 商户充电统计
      {
        path: 'dataStatistics/platformStatistics/merchanChargingStatistics/List',
        name: 'merchanChargingStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'merchanChargingStatisticsList' */ '../views/dataStatistics/platformStatistics/merchanChargingStatistics/List.vue'
          ),
        meta: {
          pageTitle: '商户充电统计',
        },
      },
      // 数据统计 平台统计 电站充电统计
      {
        path: 'dataStatistics/platformStatistics/stationChargingStatistics/List',
        name: 'stationChargingStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'merchanChargingStatisticsList' */ '../views/dataStatistics/platformStatistics/stationChargingStatistics/List.vue'
          ),
        meta: {
          pageTitle: '电站充电统计',
        },
      },
      // 数据统计 充值退款统计
      {
        path: 'dataStatistics/platformStatistics/amountDetailStatistics/List',
        name: 'amountDetailStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'amountDetailStatisticsList' */ '../views/dataStatistics/platformStatistics/amountDetailStatistics/List.vue'
          ),
        meta: {
          pageTitle: '充值退款统计',
        },
      },
      // 数据统计 商户统计 充电趋势统计
      {
        path: 'dataStatistics/merchantStatistics/List',
        name: 'merchantStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'merchantStatisticsList' */ '../views/dataStatistics/merchantStatistics/List.vue'
          ),
        meta: {
          pageTitle: '充电趋势统计',
        },
      },
      /* *************************重构后的数据统计菜单***【淘淘充2.0改版】************************************** */
      {
        path: 'dataStatistics/operationStatistics/periodStatistics/List',
        name: 'periodStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'periodStatisticsList' */ '../views/dataStatistics/operationStatistics/periodStatistics/List.vue'
          ),
        meta: {
          pageTitle: '时期统计',
        },
      },
      {
        path: 'dataStatistics/operationStatistics/hourStatistics/List',
        name: 'hourStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'hourStatisticsList' */ '../views/dataStatistics/operationStatistics/hourStatistics/List.vue'
          ),
        meta: {
          pageTitle: '小时统计',
        },
      },
      {
        path: 'dataStatistics/operationStatistics/flowStatistics/List',
        name: 'flowStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'flowStatisticsList' */ '../views/dataStatistics/operationStatistics/flowStatistics/List.vue'
          ),
        meta: {
          pageTitle: '流水统计',
        },
      },
      {
        path: 'dataStatistics/operationStatistics/flowStatistics/detail',
        name: 'flowStatisticsDetail',
        component: () =>
          import(
            /* webpackChunkName: 'flowStatisticsDetail' */ '../views/dataStatistics/operationStatistics/flowStatistics/Detail.vue'
          ),
        meta: {
          pageTitle: '流水详情',
        },
      },
      {
        path: 'dataStatistics/chargingStatistics/powerstationStatistics/List',
        name: 'powerstationStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'powerstationStatisticsList' */ '../views/dataStatistics/chargingStatistics/powerstationStatistics/List.vue'
          ),
        meta: {
          pageTitle: '电站统计',
        },
      },
      {
        path: 'dataStatistics/chargingStatistics/terminalStatistics/List',
        name: 'terminalStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'terminalStatisticsList' */ '../views/dataStatistics/chargingStatistics/terminalStatistics/List.vue'
          ),
        meta: {
          pageTitle: '终端统计',
        },
      },
      {
        path: 'dataStatistics/chargingStatistics/merchantStatistics/List',
        name: 'merchantStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'merchantStatisticsList' */ '../views/dataStatistics/chargingStatistics/merchantStatistics/List.vue'
          ),
        meta: {
          pageTitle: '商户统计',
        },
      },
    ],
  },
  // 电站管理
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  电站资产 电站管理 直联电站
      {
        path: 'plantAssets/powerStationManagement/directConnectionStation/List',
        name: 'directConnectionStationList',
        component: () =>
          import(
            /* webpackChunkName: 'directConnectionStationList' */ '../views/plantAssets/powerStationManagement/directConnectionStation/List.vue'
          ),
        meta: {
          pageTitle: '直联电站',
        },
      },
      //  电站资产 电站管理 直联电站详情
      {
        path: 'plantAssets/powerStationManagement/directConnectionStation/Detail',
        name: 'directConnectionStationDetail',
        component: () =>
          import(
            /* webpackChunkName: 'directConnectionStationDetail' */ '../views/plantAssets/powerStationManagement/directConnectionStation/Detail.vue'
          ),
        meta: {
          pageTitle: '直联电站详情',
        },
      },
      //  电站资产 电站管理 互联电站
      {
        path: 'plantAssets/powerStationManagement/interconnectionStation/List',
        name: 'interconnectionStationList',
        component: () =>
          import(
            /* webpackChunkName: 'interconnectionStationList' */ '../views/plantAssets/powerStationManagement/interconnectionStation/List.vue'
          ),
        meta: {
          pageTitle: '互联电站',
        },
      },
      //  电站资产 电站管理 互联电站详情
      {
        path: 'plantAssets/powerStationManagement/interconnectionStation/Detail',
        name: 'interconnectionStationDetail2',
        component: () =>
          import(
            /* webpackChunkName: 'interconnectionStationDetail' */ '../views/plantAssets/powerStationManagement/interconnectionStation/Detail.vue'
          ),
        meta: {
          pageTitle: '互联电站详情',
        },
      },
      //  电站资产 电桩管理 直联电桩
      {
        path: 'plantAssets/powerPileManagement/directConnectionPile/List',
        name: 'directConnectionPileList',
        component: () =>
          import(
            /* webpackChunkName: 'directConnectionPileList' */ '../views/plantAssets/powerPileManagement/directConnectionPile/List.vue'
          ),
        meta: {
          pageTitle: '直联电桩',
        },
      },
      //  电站资产 电桩管理 互联电桩
      {
        path: 'plantAssets/powerPileManagement/interconnectionPile/List',
        name: 'interconnectionPileList',
        component: () =>
          import(
            /* webpackChunkName: 'directConnectionPileList' */ '../views/plantAssets/powerPileManagement/interconnectionPile/List.vue'
          ),
        meta: {
          pageTitle: '互联电桩',
        },
      },
      //  电站资产 电桩管理 社区私桩
      {
        path: '/plantAssets/powerStationManagement/privatePile/List',

        name: 'privatePilePileList',
        component: () =>
          import(
            /* webpackChunkName: 'directConnectionPileList' */ '../views/plantAssets/powerPileManagement/privatePile/List.vue'
          ),
        meta: {
          pageTitle: '社区私桩',
        },
      },
      //  电站资产 有序管理 有序台区管理
      {
        path: 'plantAssets/orderlyManagement/List',
        name: 'orderlyManagementList',
        component: () =>
          import(
            /* webpackChunkName: 'customerAccountsDetail' */ '../views/plantAssets/orderlyManagement/List.vue'
          ),
        meta: {
          pageTitle: '有序管理',
        },
      },
      //  电站设备 有序管理 有序控制
      {
        path: 'plantAssets/orderlyManagement/orderlyControl/List',
        name: 'orderlyControlList',
        component: () =>
          import(
            /* webpackChunkName: 'orderlyControlList`' */ '../views/plantAssets/orderlyManagement/orderlyControl/List.vue'
          ),
        meta: {
          pageTitle: '有序控制',
        },
      },
      //  电站设备 有序管理 有序控制详情
      {
        path: 'plantAssets/orderlyManagement/orderlyControl/Detail',
        name: 'orderlyControlDetail',
        component: () =>
          import(
            /* webpackChunkName: 'orderlyControlDetail' */ '../views/plantAssets/orderlyManagement/orderlyControl/Detail.vue'
          ),
        meta: {
          pageTitle: '有序控制详情',
        },
      },
      //  电站设备 有序管理 有序统计
      {
        path: 'plantAssets/orderlyManagement/orderlyStatistics/List',
        name: 'orderlyStatisticsList',
        component: () =>
          import(
            /* webpackChunkName: 'orderlyStatisticsList`' */ '../views/plantAssets/orderlyManagement/orderlyStatistics/List.vue'
          ),
        meta: {
          pageTitle: '有序统计',
        },
      },
      //  电站设备 有序管理 有序记录
      {
        path: 'plantAssets/orderlyManagement/orderlyRecord/List',
        name: 'orderlyRecordList',
        component: () =>
          import(
            /* webpackChunkName: 'orderlyRecordList`' */ '../views/plantAssets/orderlyManagement/orderlyRecord/List.vue'
          ),
        meta: {
          pageTitle: '有序记录',
        },
      },
      //  电站资产 道闸管理 道闸管理列表
      {
        path: 'plantAssets/gateManagement/List',
        name: 'gateManagementList',
        component: () =>
          import(
            /* webpackChunkName: 'gateManagementList' */ '../views/plantAssets/gateManagement/List.vue'
          ),
        meta: {
          pageTitle: '道闸管理',
        },
      },
      //  电站资产 道闸管理 道闸管理详情
      {
        path: 'plantAssets/gateManagement/Detail',
        name: 'gateManagementDetail',
        meta: {
          pageTitle: '道闸管理详情',
        },
        component: () =>
          import(
            /* webpackChunkName: 'gateManagementDetail' */ '../views/plantAssets/gateManagement/Detail.vue'
          ),
      },
      //  电站资产 品牌型号 品牌型号
      {
        path: 'plantAssets/brandAndModel/List',
        name: 'brandAndModelList',
        component: () =>
          import(
            /* webpackChunkName: 'brandAndModelList' */ '../views/plantAssets/brandAndModel/List.vue'
          ),
        meta: {
          pageTitle: '品牌型号',
        },
      },
      //  电站设备 出厂测试
      {
        path: 'plantAssets/factoryTest/List',
        name: 'factoryTestList',
        component: () =>
          import(
            /* webpackChunkName: 'factoryTestList' */ '../views/plantAssets/factoryTest/List.vue'
          ),
        meta: {
          pageTitle: '出厂测试',
        },
      },
    ],
  },
  // 运营管理
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  运营管理 用户端广告 广告列表
      {
        path: '/marketingCenter/clientAds/List',
        name: 'clientAdsList',
        component: () => import('../views/marketingCenter/clientAds/List.vue'),
        meta: {
          pageTitle: '用户端广告列表',
        },
      },
      //  运营管理 广告管理 用户端广告 广告编辑
      {
        path: '/marketingCenter/clientAds/Detail',
        name: 'clientAdsDetail',
        component: () => import('../views/marketingCenter/clientAds/Detail.vue'),
        meta: {
          pageTitle: '用户端广告详情',
        },
      },
      //  运营管理 标签分组 用户分组 列表
      {
        path: '/marketingCenter/labelUsers/List',
        name: 'labelUsersList',
        component: () => import('../views/marketingCenter/labelUsers/List.vue'),
        meta: {
          pageTitle: '用户分组列表',
        },
      },
      //  运营管理 标签分组 用户分组 详情
      {
        path: '/marketingCenter/labelUsers/Detail',
        name: 'labelUsersDetail',
        component: () => import('../views/marketingCenter/labelUsers/Detail.vue'),
        meta: {
          pageTitle: '用户分组详情',
        },
      },
      //  运营管理 标签分组 电站分组 列表
      {
        path: '/marketingCenter/labelStations/List',
        name: 'labelStationsList',
        component: () => import('../views/marketingCenter/labelStations/List.vue'),
        meta: {
          pageTitle: '电站分组列表',
        },
      },
      //  运营管理 标签分组 电站分组 编辑
      {
        path: '/marketingCenter/labelStations/Detail',
        name: 'labelStationsDetail',
        component: () => import('../views/marketingCenter/labelStations/Detail.vue'),
        meta: {
          pageTitle: '电站分组详情',
        },
      },

      /** ############################################ 运营中心 ############################################## */
      {
        path: '/operationCenter/priceManage/chargingPriceStrategy',
        name: 'chargingPriceStrategyList',
        component: () =>
          import('../views/operationCenter/priceManage/chargingPriceStrategy/List.vue'),
        meta: {
          pageTitle: '充电价格策略',
        },
      },
      {
        path: '/operationCenter/priceManage/chargingPriceStrategy/details',
        name: 'chargingPriceStrategyDetail',
        component: () =>
          import('../views/operationCenter/priceManage/chargingPriceStrategy/Detail.vue'),
        meta: {
          pageTitle: '充电价格策略详情',
        },
      },
      {
        path: '/operationCenter/priceManage/chargingPriceTemplate',
        name: 'chargingPriceTemplateList',
        component: () =>
          import('../views/operationCenter/priceManage/chargingPriceTemplate/List.vue'),
        meta: {
          pageTitle: '充电价格模板',
        },
      },
      {
        path: '/operationCenter/priceManage/chargingPriceTemplate/details',
        name: 'chargingPriceTemplateDetail',
        component: () =>
          import('../views/operationCenter/priceManage/chargingPriceTemplate/Detail.vue'),
        meta: {
          pageTitle: '充电价格模板详情',
        },
      },
      {
        path: '/operationCenter/priceManage/occupyingPriceStrategy',
        name: 'occupyingPriceStrategyList',
        component: () =>
          import('../views/operationCenter/priceManage/occupyingPriceStrategy/List.vue'),
        meta: {
          pageTitle: '占位价格策略',
        },
      },
      {
        path: '/operationCenter/priceManage/occupyingPriceStrategy/details',
        name: 'occupyingPriceStrategyDetail',
        component: () =>
          import('../views/operationCenter/priceManage/occupyingPriceStrategy/Detail.vue'),
        meta: {
          pageTitle: '占位价格策略详情',
        },
      },
      {
        path: '/operationCenter/priceManage/royaltyStrategy',
        name: 'royaltyStrategyList',
        component: () => import('../views/operationCenter/priceManage/royaltyStrategy/List.vue'),
        meta: {
          pageTitle: '抽成费用策略',
        },
      },
      {
        path: '/operationCenter/priceManage/bigcustomerStrategy',
        name: 'bigcustomerStrategyList',
        component: () => import('../views/operationCenter/priceManage/bigCustomerPricing/List.vue'),
        meta: {
          pageTitle: '大客户定价',
        },
      },
      {
        path: '/operationCenter/priceManage/bigcustomerStrategy/details',
        name: 'bigcustomerStrategyDetail',
        component: () =>
          import('../views/operationCenter/priceManage/bigCustomerPricing/Detail.vue'),
        meta: {
          pageTitle: '大客户定价详情',
        },
      },
      {
        path: '/operationCenter/vipManage/vipManage',
        name: 'vipManageList',
        component: () => import('../views/operationCenter/vipManage/vipManage/List.vue'),
        meta: {
          pageTitle: '会员管理',
        },
      },
      {
        path: '/operationCenter/vipManage/vipManage/details',
        name: 'vipManageDetail',
        component: () => import('../views/operationCenter/vipManage/vipManage/Detail.vue'),
        meta: {
          pageTitle: '会员详情',
        },
      },
      {
        path: '/operationCenter/vipManage/vipManage/integralFlow',
        name: 'integralFlow',
        component: () => import('../views/operationCenter/vipManage/vipManage/IntegralFlow.vue'),
        meta: {
          pageTitle: '积分流水',
        },
      },
      {
        path: '/operationCenter/vipManage/vipManage/growthValueFlow',
        name: 'growthValueFlow',
        component: () => import('../views/operationCenter/vipManage/vipManage/GrowthValueFlow.vue'),
        meta: {
          pageTitle: '成长值流水',
        },
      },
      {
        path: '/operationCenter/vipManage/vipLevel',
        name: 'vipLevelList',
        component: () => import('../views/operationCenter/vipManage/vipLevel/List.vue'),
        meta: {
          pageTitle: '会员等级配置',
        },
      },
      {
        path: '/operationCenter/orderManage/orderEvaluation',
        name: 'orderEvaluationList',
        component: () => import('../views/operationCenter/orderManage/orderEvaluation/List.vue'),
        meta: {
          pageTitle: '订单评价',
        },
      },
      {
        path: '/operationCenter/orderManage/orderEvaluation/details',
        name: 'orderEvaluationDetail',
        component: () => import('../views/operationCenter/orderManage/orderEvaluation/Detail.vue'),
        meta: {
          pageTitle: '订单评价',
        },
      },
      {
        path: '/operationCenter/orderManage/orderEvaluation/blockKeywords',
        name: 'orderEvaluationDetail',
        component: () =>
          import('../views/operationCenter/orderManage/orderEvaluation/BlockKeywords.vue'),
        meta: {
          pageTitle: '屏蔽关键字',
        },
      },
      {
        path: '/operationCenter/operationConfig/siteOperationConfig',
        name: 'siteOperationConfigList',
        component: () =>
          import('../views/operationCenter/operationConfig/siteOperationConfig/List.vue'),
        meta: {
          pageTitle: '站点运营配置',
        },
      },
      {
        path: '/operationCenter/operationConfig/userBlackList',
        name: 'userBlackList',
        component: () => import('../views/operationCenter/operationConfig/userBlackList/List.vue'),
        meta: {
          pageTitle: '用户黑名单',
        },
      },
      {
        path: '/operationCenter/operationConfig/userBlackList/details',
        name: 'userBlackListDetail',
        component: () =>
          import('../views/operationCenter/operationConfig/userBlackList/Detail.vue'),
        meta: {
          pageTitle: '用户黑名单详情',
        },
      },
      {
        path: '/operationCenter/propertymentConfig/propertymentManage',
        name: 'propertymentList',
        component: () =>
          import('../views/operationCenter/propertymentConfig/propertymentManage/List.vue'),
        meta: {
          pageTitle: '物业管理',
        },
      },
      {
        path: '/operationCenter/propertymentConfig/propertySiteManage',
        name: 'propertymentList',
        component: () =>
          import('../views/operationCenter/propertymentConfig/propertySiteManage/List.vue'),
        meta: {
          pageTitle: '场地管理',
        },
      },
      {
        path: '/operationCenter/propertymentConfig/propertymentManage/detail',
        name: 'propertymentDetail',
        component: () =>
          import('../views/operationCenter/propertymentConfig/propertymentManage/Detail.vue'),
        meta: {
          pageTitle: '物业详情',
        },
      },
      {
        path: '/operationCenter/investmentConfig/investmentManage',
        name: 'investmentList',
        component: () =>
          import('../views/operationCenter/investmentConfig/investmentManage/List.vue'),
        meta: {
          pageTitle: '投资方管理',
        },
      },
      {
        path: '/operationCenter/investmentConfig/investmentManage/detail',
        name: 'investmentDetail',
        component: () =>
          import('../views/operationCenter/investmentConfig/investmentManage/Detail.vue'),
        meta: {
          pageTitle: '投资方详情',
        },
      },
    ],
  },
  // 营销管理
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  1.实体充电卡
      {
        path: '/marketingCenter/chargingCard/chargingCardManage',
        name: 'chargingCardManageList',
        component: () => import('../views/marketingCenter/chargingCard/chargingCardManage/List'),
        meta: {
          pageTitle: '充电卡管理',
        },
      },
      {
        path: '/marketingCenter/chargingCard/offlineCardManage',
        name: 'offlineCardManageList',
        component: () => import('../views/marketingCenter/chargingCard/offlineCardManage/List'),
        meta: {
          pageTitle: '离线卡管理',
        },
      },
      {
        path: '/marketingCenter/chargingCard/offlineCardManage/detail',
        name: 'offlineCardManageDetail',
        component: () => import('../views/marketingCenter/chargingCard/offlineCardManage/Detail'),
        meta: {
          pageTitle: '离线卡详情',
        },
      },

      //  2.平台营销工具
      {
        path: '/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity',
        name: 'platformPriceActivityList',
        component: () =>
          import(
            '../views/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity/List'
          ),
        meta: {
          pageTitle: '平台价格活动',
        },
      },

      {
        path: '/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity/details',
        name: 'platformPriceActivityDetail',
        component: () =>
          import(
            '../views/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity/Detail'
          ),
        meta: {
          pageTitle: '平台价格活动详情',
        },
      },
      {
        path: '/marketingCenter/platformMarketingTools/marketingActivity/platformCouponActivity',
        name: 'platformCouponActivityList',
        component: () =>
          import(
            '../views/marketingCenter/platformMarketingTools/marketingActivity/platformCouponActivity/List'
          ),
        meta: {
          pageTitle: '平台卡券活动',
        },
      },
      {
        path: '/marketingCenter/platformMarketingTools/marketingActivity/pickUpRecord',
        name: 'platformCouponActivityPickUpRecord',
        component: () =>
          import(
            '../views/marketingCenter/platformMarketingTools/marketingActivity/platformCouponActivity/PickUpRecord'
          ),
        meta: {
          pageTitle: '平台卡券活动',
        },
      },
      {
        path: '/marketingCenter/details',
        name: 'detail',
        component: () =>
          import(
            '../views/marketingCenter/platformMarketingTools/marketingActivity/platformCouponActivity/Detail'
          ),
        meta: {
          pageTitle: '平台卡券活动详情',
        },
      },
      // 营销管理 平台营销工具 平台卡券管理 优惠券管理
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/coupon',
        name: 'platformCouponList',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/coupon/List'
          ),
        meta: {
          pageTitle: '优惠券管理',
        },
      },
      // 营销管理 平台营销工具 平台卡券管理 优惠券管理 优惠券明细
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/coupon/particulars',
        name: 'platformCouponParticulars',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/coupon/Particulars'
          ),
        meta: {
          pageTitle: '优惠券明细',
        },
      },
      // 营销管理 平台营销工具 平台卡券管理 优惠券管理 优惠券详情
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/coupon/details',
        name: 'platformCouponDetail',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/coupon/Detail'
          ),
        meta: {
          pageTitle: '优惠券详情',
        },
      },
      // 营销管理 平台营销工具 平台卡券管理 抵用卡管理
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/creditCard',
        name: 'platformCreditCardList',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/creditCard/List'
          ),
        meta: {
          pageTitle: '抵用卡管理',
          subTitle:
            '抵用卡是服务费打折优惠卡，可以使用多次。直到面额用完，或者抵用卡过期，该卡使用结束',
        },
      },
      // 营销管理 平台营销工具 平台卡券管理 抵用卡管理 抵用卡明细
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/creditCard/particulars',
        name: 'platformCreditCardParticulars',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/creditCard/Particulars'
          ),
        meta: {
          pageTitle: '抵用卡明细',
        },
      },
      // 营销管理 平台营销工具 平台卡券管理 抵用卡管理 抵用卡详情
      {
        path: '/marketingCenter/platformMarketingTools/platformCoupon/creditCard/details',
        name: 'platformCreditCardDetail',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/platformCoupon/creditCard/Detail'
          ),
        meta: {
          pageTitle: '抵用卡详情',
        },
      },
      // 营销管理 平台营销工具 平台卡券管理 私桩红包
      {
        path: '/marketingCenter/platformMarketingTools/privateRedEnvelope',
        name: 'privateRedEnvelope',
        component: () =>
          import(
            /* webpackChunkName: 'platformMarketingTools' */ '../views/marketingCenter/platformMarketingTools/privateRedEnvelope/List'
          ),
        meta: {
          pageTitle: '私桩红包',
        },
      },
      // 营销管理 平台营销工具 积分商城 商品管理
      {
        path: '/marketingCenter/platformMarketingTools/pointsMall/commodity',
        name: 'commodityList',
        component: () =>
          import('../views/marketingCenter/platformMarketingTools/pointsMall/commodity/List'),
        meta: {
          pageTitle: '商品管理',
        },
      },
      // 营销管理 平台营销工具 积分商城 商品管理 商品详情
      {
        path: '/marketingCenter/platformMarketingTools/pointsMall/commodity/details',
        name: 'commodityDetail',
        component: () =>
          import('../views/marketingCenter/platformMarketingTools/pointsMall/commodity/Detail'),
        meta: {
          pageTitle: '商品管理',
        },
      },
      // 营销管理 平台营销工具 积分商城 兑换管理
      {
        path: '/marketingCenter/platformMarketingTools/pointsMall/exchange',
        name: 'exchangeList',
        component: () =>
          import('../views/marketingCenter/platformMarketingTools/pointsMall/exchange/List'),
        meta: {
          pageTitle: '兑换管理',
        },
      },
      // 营销管理 平台营销工具 积分商城 兑换管理 兑换详情
      {
        path: '/marketingCenter/platformMarketingTools/pointsMall/exchange/details',
        name: 'exchangeDetail',
        component: () =>
          import('../views/marketingCenter/platformMarketingTools/pointsMall/exchange/Detail'),
        meta: {
          pageTitle: '兑换详情',
        },
      },
      //  3.商户营销工具
      // 营销管理 商户营销工具 营销活动 商户价格活动
      {
        path: '/marketingCenter/merchantMarketingTools/marketingActivity/merchantPriceActivity',
        name: 'merchantPriceActivityList',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/marketingActivity/merchantPriceActivity/List'
          ),
        meta: {
          pageTitle: '商户价格活动',
        },
      },
      {
        path: '/marketingCenter/merchantMarketingTools/marketingActivity/merchantPriceActivity/detail',
        name: 'merchantPriceActivityDetail',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/marketingActivity/merchantPriceActivity/Detail'
          ),
        meta: {
          pageTitle: '商户价格活动',
        },
      },
      // 营销管理 商户营销工具 营销活动 商户卡券活动
      {
        path: '/marketingCenter/merchantMarketingTools/marketingActivity/merchantCouponActivity',
        name: 'merchantCouponActivityList',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/marketingActivity/merchantCouponActivity/List'
          ),
        meta: {
          pageTitle: '商户卡券活动',
        },
      },
      {
        path: '/marketingCenter/merchant/details',
        name: 'merchantCouponActivityDetail',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/marketingActivity/merchantCouponActivity/Detail'
          ),
        meta: {
          pageTitle: '商户卡券活动详情',
        },
      },
      {
        path: '/marketingCenter/merchantMarketingTools/marketingActivity/pickUpRecord',
        name: 'merchantCouponActivityPickUpRecord',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/marketingActivity/merchantCouponActivity/PickUpRecord'
          ),
        meta: {
          pageTitle: '商户卡券领取记录',
        },
      },
      // 营销管理 商户营销工具 商户卡券管理 优惠券管理
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/coupon',
        name: 'merchantCouponList',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/coupon/List'
          ),
        meta: {
          pageTitle: '优惠券管理',
        },
      },
      // 营销管理 商户营销工具 商户卡券管理 优惠券管理 优惠券明细
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/coupon/particulars',
        name: 'merchantCouponParticulars',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/coupon/Particulars'
          ),
        meta: {
          pageTitle: '优惠券明细',
        },
      },
      // 营销管理 商户营销工具 商户卡券管理 优惠券管理 优惠券详情
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/coupon/details',
        name: 'merchantCouponDetail',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/coupon/Detail'
          ),
        meta: {
          pageTitle: '优惠券详情',
        },
      },
      // 营销管理 商户营销工具 商户卡券管理 抵用卡管理
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard',
        name: 'merchantCreditCardList',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard/List'
          ),
        meta: {
          pageTitle: '抵用卡管理',
        },
      },
      // 营销管理 商户营销工具 商户卡券管理 抵用卡管理 抵用卡明细
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard/particulars',
        name: 'merchantCreditCardParticulars',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard/Particulars'
          ),
        meta: {
          pageTitle: '抵用卡明细',
        },
      },
      // 营销管理 商户营销工具 商户卡券管理 抵用卡管理 抵用卡详情
      {
        path: '/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard/details',
        name: 'merchantCreditCardDetail',
        component: () =>
          import(
            /* webpackChunkName: 'merchantMarketingTools' */ '../views/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard/Detail'
          ),
        meta: {
          pageTitle: '抵用卡详情',
        },
      },
      // 4.广告管理
      // 5.标签分组
    ],
  },
  // 商户管理
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  商户管理 平台商户 商户列表
      {
        path: '/merchantCenter/merchantPlatform/List',
        name: 'platformMerchantList',
        component: () => import('../views/merchantCenter/merchantPlatform/List.vue'),
        meta: {
          pageTitle: '平台商户管理',
        },
      },
      //  商户管理 平台商户 商户详情
      {
        path: '/merchantCenter/merchantPlatform/Detail',
        name: 'platformMerchantDetail',
        component: () => import('../views/merchantCenter/merchantPlatform/Detail.vue'),
        meta: {
          pageTitle: '商户详情',
        },
      },
      //  商户管理 互联商户 商户列表
      {
        path: '/merchantCenter/merchantUnion/List',
        name: 'merchantUnionList',
        component: () => import('../views/merchantCenter/merchantUnion/List.vue'),
        meta: {
          pageTitle: '互联商户管理',
        },
      },
    ],
  },
  // 司机管理
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  司机管理 充电客户 客户机构列表
      {
        path: '/customerCenter/customerCharge/organization/organizationList',
        name: 'customerChargeList',
        component: () => import('../views/customerCenter/customerCharge/organization/List.vue'),
        meta: {
          pageTitle: '客户机构列表',
        },
      },
      //  司机管理 充电客户 客户司机列表
      {
        path: '/customerCenter/customerCharge/customerDriver/customerDriverList',
        name: 'customerDriverList',
        component: () => import('../views/customerCenter/customerCharge/customerDriver/List.vue'),
        meta: {
          pageTitle: '客户司机列表',
        },
      },
      //  司机管理 充电客户 客户司机详情
      {
        path: '/customerCenter/customerCharge/customerDriver/customerDriverDetail',
        name: 'customerDriverDetail',
        component: () => import('../views/customerCenter/customerCharge/customerDriver/Detail.vue'),
        meta: {
          pageTitle: '司机详情',
        },
      },
      //  司机管理 充电客户 客户车辆列表
      {
        path: '/customerCenter/customerCharge/customerVehicle/customerVehicleList',
        name: 'customerVehicleList',
        component: () => import('../views/customerCenter/customerCharge/customerVehicle/List.vue'),
        meta: {
          pageTitle: '客户车辆列表',
        },
      },
      //  司机管理 充电客户 C端司机列表
      {
        path: '/customerCenter/customerCustomer/customerCustomerList',
        name: 'customerCustomerList',
        component: () => import('../views/customerCenter/customerCustomer/List.vue'),
        meta: {
          pageTitle: 'C端司机列表',
        },
      },
      //  司机管理 充电客户 C端司机详情
      {
        path: '/customerCenter/customerCustomer/customerCustomerDetail',
        name: 'customerCustomerDetail',
        component: () => import('../views/customerCenter/customerCustomer/Detail.vue'),
        meta: {
          pageTitle: 'C端司机详情',
        },
      },
      //  司机管理 充电客户 私桩用户账号列表
      {
        path: '/customerCenter/customerCustomer/privateCustomer',
        name: 'customerCustomerList',
        component: () =>
          import('../views/customerCenter/customerCustomer/privateCustomer/List.vue'),
        meta: {
          pageTitle: '私桩用户账号',
        },
      },
      //  司机管理 C端用户统计 C端用户统计列表
      {
        path: '/customerCenter/customerCustomer/customerStatistics',
        name: 'customerStatisticsList',
        component: () =>
          import('../views/customerCenter/customerCustomer/customerStatistics/List.vue'),
        meta: {
          pageTitle: 'C端用户统计',
        },
      },
      //  司机管理 内部司机 子公司管理列表
      {
        path: '/customerCenter/internalDriver/subsidiaryCompany/subsidiaryCompanyList',
        name: 'subsidiaryCompanyList',
        component: () =>
          import('../views/customerCenter/internalDriver/subsidiaryCompany/List.vue'),
        meta: {
          pageTitle: '机构列表',
        },
      },
      //  司机管理 内部司机 机构车辆列表
      {
        path: '/customerCenter/internalDriver/subsidiaryVehicle/subsidiaryVehicleList',
        name: 'subsidiaryVehicleList',
        component: () =>
          import('../views/customerCenter/internalDriver/subsidiaryVehicle/List.vue'),
        meta: {
          pageTitle: '机构车辆列表',
        },
      },
      //  司机管理 内部司机 子公司司机列表
      {
        path: '/customerCenter/internalDriver/subsidiaryDriver/subsidiaryDriverList',
        name: 'subsidiaryDriverList',
        component: () => import('../views/customerCenter/internalDriver/subsidiaryDriver/List.vue'),
        meta: {
          pageTitle: '机构司机',
        },
      },
      //  司机管理 内部司机 子公司司机详情
      {
        path: '/customerCenter/internalDriver/subsidiaryDriver/subsidiaryDriverDetail',
        name: 'subsidiaryDriverDetail',
        component: () =>
          import('../views/customerCenter/internalDriver/subsidiaryDriver/Detail.vue'),
        meta: {
          pageTitle: '子公司司机详情',
        },
      },
      //  司机管理 互联互通客户 互联互通客户管理 列表
      {
        path: '/customerCenter/customerUnion/customerUnion/customerUnionList',
        name: 'customerUnionList',
        component: () => import('../views/customerCenter/customerUnion/customerUnion/List.vue'),
        meta: {
          pageTitle: '互联互通客户',
        },
      },
      //  司机管理 互联互通客户 互联互通客户管理 详情
      {
        path: '/customerCenter/customerUnion/customerUnion/customerUnionDetail',
        name: 'customerUnionDetail',
        component: () => import('../views/customerCenter/customerUnion/customerUnion/Detail.vue'),
        meta: {
          pageTitle: '互联互通客户详情',
        },
      },
      //  司机管理 互联互通客户 互联互通秘钥管理 详情
      {
        path: '/customerCenter/customerUnion/cipherUnion/cipherUnionList',
        name: 'cipherUnionList',
        component: () => import('../views/customerCenter/customerUnion/cipherUnion/List.vue'),
        meta: {
          pageTitle: '互联推送管理',
        },
      },
      //  司机管理 互联互通客户 互联互通秘钥管理 详情
      {
        path: '/customerCenter/customerUnion/cipherUnion/cipherUnionDetail',
        name: 'cipherUnionDetail',
        component: () => import('../views/customerCenter/customerUnion/cipherUnion/Detail.vue'),
        meta: {
          pageTitle: '互联推送详情',
        },
      },
    ],
  },
  // 运维管理
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  运维管理 告警中心 告警设置
      {
        path: '/operationManagement/alarmInfo/alarmSettingList',
        name: 'alarmInfoSettingList',
        component: () => import('../views/operationManagement/alarmInfo/alarmSetting/List.vue'),
        meta: {
          pageTitle: '告警设置',
        },
      },
      //  运维管理 告警中心 告警信息
      {
        path: '/operationManagement/alarmInfo/omAlarmsInfoList',
        name: 'omAlarmsInfoList',
        component: () => import('../views/operationManagement/alarmInfo/OMAlarmsInfoList.vue'),
        meta: {
          pageTitle: '告警信息',
        },
      },
      //  运维管理 告警中心 告警统计
      {
        path: '/operationManagement/alarmInfo/alarmStatistics',
        name: 'alarmStatistics',
        component: () => import('../views/operationManagement/alarmInfo/alarmStatistics.vue'),
        meta: {
          pageTitle: '告警统计',
        },
      },
      //  运维管理 设备运维 设备报文
      {
        path: '/operationManagement/deviceOM/deviceMessageList',
        name: 'deviceMessageList',
        component: () => import('../views/operationManagement/deviceOM/DeviceMessageList.vue'),
        meta: {
          pageTitle: '设备报文',
        },
      },
      //  运维管理 设备运维 远程升级
      {
        path: '/operationManagement/deviceOM/remoteUpgradeList',
        name: 'remoteUpgradeList',
        component: () => import('../views/operationManagement/deviceOM/RemoteUpgradeList.vue'),
        meta: {
          pageTitle: '远程升级',
        },
      },
      //  运维管理 运维信息 运维信息
      {
        path: '/operationManagement/operationInfo/operationInfo',
        name: 'operationInfoList',
        component: () =>
          import('../views/operationManagement/operationInfo/operationInfo/List.vue'),
        meta: {
          pageTitle: '运维信息',
        },
      },
      //  用户反馈
      {
        path: '/operationManagement/feedback/feedbackList',
        name: 'feedbackList',
        component: () => import('../views/operationManagement/feedback/List.vue'),
        meta: {
          pageTitle: '反馈信息',
        },
      },
      {
        path: '/operationManagement/feedback/feedbackDetails',
        name: 'feedbackList',
        component: () => import('../views/operationManagement/feedback/Detail.vue'),
        meta: {
          pageTitle: '反馈信息',
        },
      },
      //  菜单管理 前端自用
      {
        path: '/menuSetting',
        name: 'menuSetting',
        component: () => import('../views/systemSettings/menuSetting/index.vue'),
        meta: {
          pageTitle: '菜单管理',
        },
      },
    ],
  },
  // 系统设置
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  系统设置 系统操作日志 操作日志
      {
        path: 'systemSettings/operationLog/operationLog',
        name: 'operationLog',
        component: () =>
          import(
            /* webpackChunkName: 'operationLog' */ '../views/systemSettings/operationLog/List.vue'
          ),
        meta: {
          pageTitle: '操作日志',
        },
      },
      //  系统设置 权限管理 账号管理
      {
        path: 'systemSettings/powerManage/accountManage',
        name: 'accountManage',
        component: () =>
          import(
            /* webpackChunkName: 'accountManage' */ '../views/systemSettings/powerManage/accountManage/List.vue'
          ),
        meta: {
          pageTitle: '账号管理',
        },
      },
      //  系统设置 权限管理 账号管理 账号详情
      {
        path: 'systemSettings/powerManage/accountManage/details',
        name: 'accountManageDetail',
        component: () =>
          import(
            /* webpackChunkName: 'accountManage' */ '../views/systemSettings/powerManage/accountManage/Detail.vue'
          ),
        meta: {
          pageTitle: '账号详情',
        },
      },
      //  系统设置 权限管理 角色管理
      {
        path: 'systemSettings/powerManage/roleManage',
        name: 'roleManage',
        component: () =>
          import(
            /* webpackChunkName: 'roleManage' */ '../views/systemSettings/powerManage/roleManage/List.vue'
          ),
        meta: {
          pageTitle: '角色管理',
        },
      },
      //  系统设置 权限管理 角色管理 角色详情
      {
        path: 'systemSettings/powerManage/roleManage/details',
        name: 'roleManageDetail',
        component: () =>
          import(
            /* webpackChunkName: 'roleManage' */ '../views/systemSettings/powerManage/roleManage/Detail.vue'
          ),
        meta: {
          pageTitle: '角色详情',
        },
      },
      //  系统设置 系统配置 系统配置
      {
        path: 'systemSettings/systemConfig/systemConfig',
        name: 'systemConfigList',
        component: () =>
          import(
            /* webpackChunkName: 'roleManage' */ '../views/systemSettings/systemConfig/systemConfig/List.vue'
          ),
        meta: {
          pageTitle: '系统配置',
        },
      },
      //  系统设置 系统消息 消息管理
      {
        path: 'systemSettings/systemNotice/noticeManage',
        name: 'noticeManageList',
        component: () =>
          import(
            /* webpackChunkName: 'noticeManage' */ '../views/systemSettings/systemNotice/noticeManage/List.vue'
          ),
        meta: {
          pageTitle: '消息管理',
        },
      },
      //  系统设置 下载中心 下载列表
      {
        path: 'systemSettings/downloadCenter',
        name: 'downloadCenterList',
        component: () =>
          import(
            /* webpackChunkName: 'noticeManage' */ '../views/systemSettings/downloadCenter/List.vue'
          ),
        meta: {
          pageTitle: '下载中心',
        },
      },
      //  系统设置 版本更新
      {
        path: 'systemSettings/upgradeManage',
        name: 'upgradeManage',
        component: () =>
          import(
            /* webpackChunkName: 'upgradeManage' */ '../views/systemSettings/upgradeManage/List.vue'
          ),
        meta: {
          pageTitle: '版本更新',
        },
      },
      //  系统设置 字典管理
      {
        path: 'systemSettings/dictManage',
        name: 'dictManage',
        component: () =>
          import(
            /* webpackChunkName: 'dictManage' */ '../views/systemSettings/dictManage/List.vue'
          ),
        meta: {
          pageTitle: '字典管理',
        },
      },
    ],
  },
  // 物业统计
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  物业统计 物业统计列表
      {
        path: 'propertyStatistics/propertySettlement',
        name: 'propertySettlement',
        component: () =>
          import(
            /* webpackChunkName: 'operationLog' */ '../views/propertyStatistics/propertySettlement/List.vue'
          ),
        meta: {
          pageTitle: '物业统计',
        },
      },
      //  物业统计 物业结算 系统结算
      {
        path: 'propertyStatistics/propertySettlement/ApplyBySystem',
        name: 'propertySettlement',
        component: () =>
          import(
            /* webpackChunkName: 'operationLog' */ '../views/propertyStatistics/propertySettlement/ApplyBySystem.vue'
          ),
        meta: {
          pageTitle: '物业结算',
        },
      },
      //  物业统计 物业结算 电表结算
      {
        path: 'propertyStatistics/propertySettlement/ApplyByMeter',
        name: 'propertySettlement',
        component: () =>
          import(
            /* webpackChunkName: 'operationLog' */ '../views/propertyStatistics/propertySettlement/ApplyByMeter.vue'
          ),
        meta: {
          pageTitle: '物业结算',
        },
      },
      {
        path: 'propertyStatistics/propertySettlement/Detail',
        name: 'propertySettlement',
        component: () =>
          import(
            /* webpackChunkName: 'operationLog' */ '../views/propertyStatistics/propertySettlement/Detail.vue'
          ),
        meta: {
          pageTitle: '物业结算详情',
        },
      },
    ],
  },
  // 立项管理
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  立项管理 公桩立项管理 投建项目
      {
        path: 'projectManage/icProject/icProject',
        name: 'icProject',
        component: () =>
          import(/* webpackChunkName: 'icProject' */ '../views/projectManage/icProject/List.vue'),
        meta: {
          pageTitle: '投建项目',
        },
      },
      //  立项管理 公桩立项管理 详情
      {
        path: 'projectManage/icProject/detail',
        name: 'icProject',
        component: () =>
          import(
            /* webpackChunkName: 'icProjectDetail' */ '../views/projectManage/icProject/Detail.vue'
          ),
        meta: {
          pageTitle: '投建项目',
        },
      },
      //  立项管理 区域管理 区域管理
      {
        path: 'projectManage/areaManage/areaManage',
        name: 'accountManage',
        component: () =>
          import(
            /* webpackChunkName: 'accountManage' */ '../views/projectManage/areaManage/List.vue'
          ),
        meta: {
          pageTitle: '区域管理',
        },
      },
    ],
  },
  // 私桩售桩
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  私桩售桩 私桩二维码
      {
        path: 'salePileManagement/salePile/qrcode',
        name: 'salePileQrcode',
        component: () =>
          import(
            /* webpackChunkName: 'operationLog' */ '../views/salePileManagement/salePile/qrcode/List.vue'
          ),
        meta: {
          pageTitle: '私桩二维码',
        },
      },
      {
        path: 'salePileManagement/salePile/examine',
        name: 'salePileExamine',
        component: () =>
          import(
            /* webpackChunkName: 'operationLog' */ '../views/salePileManagement/salePile/examine/List.vue'
          ),
        meta: {
          pageTitle: '私桩审核',
        },
      },
      {
        path: 'salePileManagement/salePile/order',
        name: 'salePileOrder',
        component: () =>
          import(
            /* webpackChunkName: 'operationLog' */ '../views/salePileManagement/salePile/order/List.vue'
          ),
        meta: {
          pageTitle: '私桩工单',
        },
      },
    ],
  },
  // 大屏
  {
    path: '/',
    component: EmptyLayout,
    children: [
      // #region 大屏
      {
        path: 'overview/operation',
        name: 'OverviewOperation',
        component: () =>
          import(/* webpackChunkName: 'OverviewOperation' */ '@/views/overview/operation/Main.vue'),
        meta: {
          pageTitle: '运营大屏',
        },
      },
      {
        path: 'overview/station',
        name: 'OverviewStation',
        component: () =>
          import(/* webpackChunkName: 'OverviewStation' */ '@/views/overview/station/Main.vue'),
        meta: {
          pageTitle: '单车大屏',
        },
      },
      // #endregion
    ],
  },
  // 私桩共享
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  私桩共享 分成费用
      {
        path: 'privatePileShare/share/dividedFee',
        name: 'dividedFee',
        component: () =>
          import(
            /* webpackChunkName: 'operationLog' */ '../views/privatePileShare/share/dividedFee/List.vue'
          ),
        meta: {
          pageTitle: '分成费用',
        },
      },
      //  私桩共享 分成信息
      {
        path: 'privatePileShare/share/dividedIntoInformations',
        name: 'divideds',
        component: () =>
          import(
            /* webpackChunkName: 'operationLog' */ '../views/privatePileShare/share/dividedIntoInformation/dividedIntoInformations.vue'
          ),
        meta: {
          pageTitle: '分成信息',
        },
      },
    ],
  },
  // 机器人
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'baseModule' */ '../layouts/Permission.vue'),
    children: [
      //  机器人 运营部门 Ai
      {
        path: 'agent/operations/ai',
        name: 'ai',
        component: () => import(/* webpackChunkName: 'ai' */ '../views/agent/operations/ai.vue'),
        meta: {
          pageTitle: 'AI活动上线',
        },
      },
      //  机器人 运营部门 Ai
      {
        path: 'agent/operations/copy',
        name: 'copy',
        component: () => import(/* webpackChunkName: 'ai' */ '../views/agent/operations/copy.vue'),
        meta: {
          pageTitle: 'AI场站复制',
        },
      },
      //  机器人 运营部门 Ai
      {
        path: 'agent/operations/online',
        name: 'online',
        component: () =>
          import(/* webpackChunkName: 'ai' */ '../views/agent/operations/online.vue'),
        meta: {
          pageTitle: 'AI场站上线',
        },
      },
      //  机器人 产研部门 代码助手
      {
        path: 'agent/research/code',
        name: 'code',
        component: () => import(/* webpackChunkName: 'ai' */ '../views/agent/research/code.vue'),
        meta: {
          pageTitle: '代码助手',
        },
      },
      //  机器人 产研部门 数据库表结构生成
      {
        path: 'agent/research/sql',
        name: 'sql',
        component: () => import(/* webpackChunkName: 'ai' */ '../views/agent/research/sql.vue'),
        meta: {
          pageTitle: '数据库表结构生成',
        },
      },
      //  机器人 产研部门 智能客服
      {
        path: 'agent/research/customer',
        name: 'ai',
        component: () =>
          import(/* webpackChunkName: 'ai' */ '../views/agent/research/customer.vue'),
        meta: {
          pageTitle: '智能客服',
        },
      },
      //  机器人 客服部门 智能客服
      {
        path: 'agent/customer/customer',
        name: 'customer',
        component: () =>
          import(/* webpackChunkName: 'ai' */ '../views/agent/customer/customer.vue'),
        meta: {
          pageTitle: '客服部门',
        },
      },
    ],
  },
  {
    path: '/NotFound',
    meta: {},
    component: () => import(/* webpackChunkName: 'baseModule' */ '../views/NotFound.vue'),
  },
  {
    path: '*',
    redirect: '/NotFound',
    // component: () => import(/* webpackChunkName: 'baseModule' */ '../views/NotFound.vue'),
  },
];
